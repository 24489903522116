#add-to-home-screen{
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    background: #3f4e73;
    width: 90%;
    border-radius: 10px;
    padding: 30px;
    box-sizing: border-box;
}
#add-to-home-screen h2{
    position: fixed;
    top: -40px;
    right: 50%;
    color: #fff;
    text-align: center;
    font-size: 17px;
    transform: translateX(50%);
}
#add-to-home-screen-logo{
    width: 200px;
    display: table;
    margin: 0 auto 25px;
}
#add-to-home-screen p{
    margin-bottom: 20px;
    color: #fff;
    line-height: 22px;
    font-weight: normal;
    text-align: justify;
}
#add-to-home-screen p:last-child{
    margin-bottom: 0;
}
#add-to-home-screen p img{
    width: 175px;
    display: inline-block;
    position: relative;
    top: 8px;
}
.add-to-home-screen-arrow{
    color: #fff;
    font-size: 30px;
    position: fixed;
    bottom: 40px;
    right: 50%;
    transform: translateX(50%);
}