#not-found{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
    text-align: center;
}
#not-found img{
    height: 180px;
    margin-bottom: 25px;
}
#not-found h5{
    color: #fff;
    font-size: 20px;
    margin-bottom: 25px;
}
.not-found-custom-btn{
    position: unset;
    margin-left: 20px;
}
.not-found-custom-btn:last-child{
    margin-left: 0;
}