#page-parent h4,#latest-games h4{
    color: #fff;
    margin-bottom: 25px;
}
.game-type,.game-row{
    height: 60px;
    background: #3f4e73 !important;
    cursor: pointer;
    overflow: hidden;
}
.game-type::before{
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 60px;
    z-index: 5;
}
.game-type::after{
    content: '';
    position: absolute;
    height: 100px;
    right: 95px;
    top: -25px;
    background: #3f4e73;
    width: 85px;
    filter: blur(9px);
    z-index: 1;
}
.game-type:last-child{
    margin-bottom: 0;
}
.game-type h5{
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    top: 18px;
    right: 125px;
    z-index: 2;
}
.game-type img{
    width: 150px;
    height: 60px;
    position: absolute;
    right: 0;
    box-shadow: -10px 0px 10px #3f4e73;
    z-index: 0;
}
.game-type span{
    z-index: 9;
}
.all-game-types{
    border-color: #cecece !important;
    color: #cecece !important;
    height: 58px;
}
.all-game-types i{
    color: #fff !important
}
.game-type-placehoder{
    display: block;
    width: 100%;
    height: 60px;
    background: linear-gradient(to right, rgba(255,255,255,0.1) 4%, rgba(255,255,255,0.3) 25%, rgba(255,255,255,0.1) 36%);
    background-size: 1000px 100%;
    margin-bottom: 15px;
    border-radius: 7px;
}
#latest-games{
    position: relative;
}
#latest-games h4{
    margin-top: 25px;
}
.game-row h5{
    display: block;
    width: 100%;
    color: #fff;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
}
.game-has-end{
    font-size: 10px;
    position: absolute;
    left: 15px;
    background: #4b934b;
    padding: 3px 5px;
    border-radius: 5px;
}
.game-has-end.white{
    background: #fff;
    color: #3f4e73
}
.slider-item{
    width: 100%;
    border: 2px solid #3f4e73;
    box-sizing: border-box;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 30px;
}
.slider-item img{
    width: 100%;
}
#slider-placeholder{
    width: 100%;
    border: 2px solid #3f4e73;
    box-sizing: border-box;
    border-radius: 5px;
    margin-top: 30px;
    background: linear-gradient(to right, rgba(255,255,255,0.1) 4%, rgba(255,255,255,0.3) 25%, rgba(255,255,255,0.1) 36%);
    background-size: 1000px 100%;
    height: 172px;
}