.auth-page-title{
    display: block;
    margin: 20px 0 15px;
    font-size: 20px;
}
.auth-page-description{
    display: block;
    width: 100%;
    font-size: 14px;
    text-align: justify;
    line-height: 22px;
    color: #888;
    margin-bottom: 20px;
}