#tutorials-box .MuiButtonBase-root{
    color: #fff;
    width: 33%;
}
#tutorials-box .MuiButtonBase-root.Mui-selected{
    color: #f0d91a;
}
#tutorials-box .MuiTabs-indicator{
    background-color: #f0d91a;
}
.tutorials-main-area{
    height: calc(100dvh - 298px);
    margin-top: 20px;
}