.coming-soon-badge{
    position: absolute;
    bottom: -18px;
    right: 50%;
    transform: translateX(50%);
    background: yellow;
    color: #000;
    padding: 3px 10px;
    border-radius: 5px;
    font-size: 13px;
}