#game-details{
    background: #8c95ab;
    border-radius: 7px;
    padding: 15px;
    box-sizing: border-box;
    font-size: 14px;
    margin-bottom: 15px;
}
#game-details ul li{
    display: block;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(0,0,0,0.3);
    color: #000
}
#game-details ul li span{
    color: #fff
}
#game-details ul li:last-child{
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}
#last-move-cards{
    padding-bottom: 16px;
}
#last-move-cards .MuiTypography-root{
    font-size: 14px;
}