.role-btn{
    background: #7d97d9 !important
}
.main-area .MuiInputBase-input{
    font-size: 15px !important;
}
.role-description{
    text-align: justify;
    line-height: 25px;
    margin-top: 11px;
    color: #575757;
}
.confirm-role{
    display: table !important;
    margin: 18px auto 0 !important;
    background: #3f4e73 !important;
}
.confirm-role.Mui-disabled{
    opacity: 0.6;
    color: #fff !important
}
.role-modal-box .MuiFormLabel-root{
    font-size: 15px !important
}
.mafia-card .MuiAvatar-root,.citizen-card .MuiAvatar-root,.independent-card .MuiAvatar-root{
    background: rgba(0,0,0,0.2);
}
.mafia-card{
    background-color: #bb4f4f !important;
}
.citizen-card{
    background-color: #76bb4f !important;
}
.independent-card{
    background-color: #c1a63e !important;
}
.main-area .MuiList-root li{
    padding-top: 5px;
    padding-bottom: 5px;
}
.card-player-dead{
    opacity: 0.5;
}
#page-parent-title{
    position: relative;
}
#game-status{
    position: absolute;
    z-index: 1;
    left: 0;
    padding: 5px 10px;
    font-size: 13px;
    top: -3px;
    border-radius: 30px;
}
#game-status.white{
    background: #fff;
}
#game-status.yellow{
    background: #fbf814;
}
#game-status.red{
    background: #e77777;
}
#handle-end-game{
    position: fixed;
    z-index: 99;
    right: 50%;
    transform: translateX(50%);
    bottom: 67px;
    background: #6e84bb !important;
    width: 135px;
    padding: 12px 0;
    border-radius: 10px 10px 0 0;
    color: #fff;
}
.players-list-title{
    display: inline-block !important;
    margin-bottom: 10px !important;
}
.custom-line-height{
    line-height: 18px !important;
}