#bottom-navigation-bar-parent{
    position: fixed;
    width: 100%;
    right: 0;
    bottom: 0;
    background: #09102a;
    padding-top: 15px;
    z-index: 10
}
#bottom-navigation-bar{
    height: 65px;
    background: #111d3c;
    border-top: 1px solid #2b3353;
}
.ios-device #bottom-navigation-bar{
    padding-bottom: 12px;
}
#bottom-navigation-bar i{
    font-size: 18px;
    margin-bottom: 3px;
    margin-top: 5px;
}
#bottom-navigation-bar button{
    color: #fff
}
#bottom-navigation-bar .MuiButtonBase-root.Mui-selected{
    color: #f0d91a !important
}