.auth-input-group{
    display: flex;
    justify-content: space-between;
}
.auth-input-group input{
    text-align: center;
}
.auth-page-footer{
    text-align: center;
    margin-top: 10px;
    font-size: 14px;
    color: #797777;
}
.auth-page-footer a{
    color: #000 !important
}
.dark .auth-page-footer a{
    color: #fff !important
}