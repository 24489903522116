#update-modal{
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100dvh;
    backdrop-filter: saturate(180%) blur(5px);
    background: hsla(0, 0%, 19.2%, 0.8);
    z-index: 99999;
}
#update-modal #update-modal-content{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 10%;
    width: 80%;
    background: #fff;
    border-radius: 10px;
    height: 230px;
    box-shadow: 0 5px 5px rgba(0,0,0,0.3);
    padding: 0 15px;
    box-sizing: border-box;
}
#update-modal-content h3{
    text-align: center;
    margin-top: 25px;
    font-size: 20px;
}
#update-modal-content p{
    width: 100%;
    text-align: justify;
    margin-top: 20px;
    line-height: 23px;
}
#update-app-btn{
    margin-top: 20px;
    height: 50px;
    background: #2b3353 !important;
}