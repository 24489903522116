.side-roles-parent{
    margin-bottom: 15px;
}
.side-role-item{
    display: block;
    width: 100%;
    height: 90px;
    border-radius: 5px;
    position: relative;
}
.side-role-item i{
    font-size: 60px;
    color: rgba(0,0,0,0.07);
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
}
.side-role-item span{
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translateY(-50%);
    text-align: center;
    color: #fff;
    font-weight: 600;
    z-index: 2;
}
.side-role-item.mafia{
    background: rgb(191, 79, 79);
}
.side-role-item.citizen{
    background: rgb(144, 168, 92);
}
.side-role-item.independent{
    background: rgb(191, 132, 89);
}