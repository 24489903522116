#auth-index{
    display: block;
    width: 100%;
    height: 100dvh;
    background: #09102a;
}
#auth-box{
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    padding: 0 20px;
    box-sizing: border-box;
}
#auth-box img{
    width: 130px;
    display: table;
    margin: 0 auto 30px;
}
#auth-box h2{
    font-size: 18px;
    margin-bottom: 15px;
}
#auth-box p{
    font-size: 13px;
    margin-bottom: 35px;
    color: #d3d3d3;
    display: inline-block;
}
#auth-box h1{
    display: inline-block;
    font-size: 16px;
    color: #fff;
    margin-left: 6px;
}
.btn-login{
    background: #7338A0 !important;
}
.btn-register{
    border-color: #9E72C3 !important;
    color: #9E72C3 !important;
}