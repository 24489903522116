#app-header{
    position: relative;
    display: block;
    padding: 15px 0;
    width: 100%;
    height: 70px;
}
#header-profile img{
    float: right;
    width: 55px;
    height: 55px;
    border-radius: 100%;
    padding: 3px;
    border: 2px solid #fff;
}
#header-profile h2{
    float: right;
    font-size: 15px;
    font-weight: bold;
    margin-top: 24px;
    margin-right: 10px;
    color: #fff
}
#header-profile h4{
    position: absolute;
    right: 75px;
    color: #c6c6c6;
    font-weight: normal;
    font-size: 13px;
    top: 53px;
}
.header-bars-btn,.header-time-btn{
    cursor: pointer;
    background: #3f4e73;
    height: 45px;
    width: 45px;
    border-radius: 5px;
    color: #fff;
    position: absolute;
    left: 0;
    top: 25px;
    font-size: 21px;
    line-height: 50px;
}
.header-time-btn{
    left: 55px;
}
.MuiList-root li{
    font-size: 15px;
}