@-webkit-keyframes splashLogo {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    50% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
       opacity: 0;
       transform: scale(0);
    }
} 
@keyframes splashLogo {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    50%,75% {
        opacity: 1;
        transform: scale(1);
    }
    100% {
       opacity: 0;
       transform: scale(0);
    }
}
@-webkit-keyframes fadeOut {
    0%,90%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
@keyframes fadeOut {
    0%,90%{
        opacity: 1;
    }
    100%{
        opacity: 0;
    }
}
#splash-screen{
    position: fixed;
    z-index: 9999;
    top: 0;
    right: 0;
    width: 100%;
    height: 100dvh;
    background: #09102a;
    -webkit-animation-name: fadeOut;
    animation-name: fadeOut;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
#splash-logo-parent{
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%,-50%);
}
#splash-logo-parent img{
    width: 140px;
    -webkit-animation-name: splashLogo;
    animation-name: splashLogo;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}