#auth-layout{
    display: block;
    width: 100%;
    position: relative;
    height: 100dvh;
    background: #0F0529;
    overflow-x: hidden;
}
#auth-layout input{
    font-size: 14px;
}
#auth-layout img{
    position: absolute;
    right: -55px;
    top: -50px;
    opacity: 0.8;
}
#auth-layout-title{
    width: 100%;
    text-align: right;
    color: #fff;
    position: absolute;
    right: 0;
    top: 90px;
    padding: 0 15px;
    box-sizing: border-box;
}
#auth-layout-title a{
    color: #fff;
    position: absolute;
    left: 15px;
    top: 5px;
    font-size: 20px;
}
#auth-layout-title a:last-child{
    left: 42px;
}
#auth-content{
    position: absolute;
    width: 100%;
    right: 0;
    bottom: 0;
    height: calc(100% - 200px);
    background: #fff;
    border-radius: 20px 20px 0 0;
}
#auth-copy-right{
    position: absolute;
    bottom: 20px;
    width: 100%;
    text-align: center;
    font-size: 13px;
    color: #888
}
#auth-copy-right a{
    color: #0F0529
}