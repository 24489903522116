#start-type > span,#start-roles > span{
    display: block;
    color: #fff;
    font-size: 14px;
    margin-bottom: 20px
}
#start-type-btns{
    background: #60719d;
    margin-bottom: 20px
}
#start-type-btns button:first-child{
    width: 60%
}
#start-type-btns button{
    color: #fff
}
#start-type-btns .MuiButtonBase-root.Mui-selected{
    background: #33487c
}
.start-roles-parent span{
    color: #fff;
    font-size: 13px !important
}
.start-role-item{
    text-align: center
}
.start-role-item .MuiSwitch-track{
    background: #fff
}
#start-roles .MuiButtonBase-root.Mui-checked + .MuiSwitch-track{
    background-color: #39ff01 !important
}
.MuiChip-root{
    color: rgba(255, 255, 255, 0.87) !important;
    background: rgba(255, 255, 255, 0.4) !important
}
.MuiDivider-withChildren::before,.MuiDivider-withChildren::after{
    border-color: rgba(255, 255, 255, 0.4) !important
}
.MuiChip-label{
    overflow: unset !important;
    text-overflow: unset !important
}
#start-type .MuiTypography-root{
    color: #fff;
    font-size: 14px;
}
#start-type .MuiSvgIcon-root{
    fill: #7a89ac
}
.role-plus-minus-parent{
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    margin-top: 10px
}
.role-plus-minus-parent button{
    min-width: unset;
    width: 35px;
    height: 35px;
    border-radius: 100%;
}
.role-plus-minus-parent > span{
    position: relative;
    top: 10px;
    font-size: 14px !important;
}
.roles-count{
    font-weight: normal;
    font-size: 12px;
    margin-right: 5px;
    color: rgba(255,255,255,0.7);
}
.start-type-placeholder{
    display: block;
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
    background: linear-gradient(to right, rgba(255,255,255,0.1) 4%, rgba(255,255,255,0.3) 25%, rgba(255,255,255,0.1) 36%);
    background-size: 1000px 100%;
}
.start-roles-placeholder-parent{
    display: flex;
    justify-content: space-between;
}

.start-roles-placeholder{
    display: block;
    width: 30%;
    height: 60px;
    margin-bottom: 10px;
    background: linear-gradient(to right, rgba(255,255,255,0.1) 4%, rgba(255,255,255,0.3) 25%, rgba(255,255,255,0.1) 36%);
    background-size: 1000px 100%;
}